import { render, staticRenderFns } from "./LinkedAccount.vue?vue&type=template&id=7bbb823b"
import script from "./LinkedAccount.vue?vue&type=script&lang=js"
export * from "./LinkedAccount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports